import React, { useEffect, useState } from 'react';
import {
  Chart as ChartJS,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bubble } from 'react-chartjs-2';
import { backendURL } from "../../App";
import overworldImage from '../../assets/overworld.png'; // Import the background image

ChartJS.register(LinearScale, PointElement, Tooltip, Legend);

const options = {
  aspectRatio: 1,
  scales: {
    x: {
      min: -1500,
      max: 1500,
    },
    y: {
      beginAtZero: false,
      min: -1500,
      max: 1500,
      reverse: true,
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        label: (context) => context.raw.label, // Show player username on hover
      },
    },
  },
};

// Function to load player images and ensure they’re fully loaded
function loadPlayerImages(players) {
  const loadImage = (player) =>
    new Promise((resolve, reject) => {
      const img = new Image();
      img.src = `${backendURL}/public/${player.username}.png`;
      img.onload = () => resolve({ username: player.username, image: img });
      img.onerror = reject;
    });

  return Promise.all(players.map(loadImage))
    .then((images) => {
      const imageMap = {};
      images.forEach(({ username, image }) => {
        imageMap[username] = image;
      });
      return imageMap;
    })
    .catch((error) => {
      console.error("Error loading images", error);
      return {};
    });
}

// Custom plugin to add a static background image
const backgroundImagePlugin = {
  id: 'backgroundImagePlugin',
  beforeDatasetsDraw(chart) {
    const { ctx, chartArea } = chart;

    if (!chart.backgroundImage) {
      chart.backgroundImage = new Image();
      chart.backgroundImage.src = overworldImage;
      chart.backgroundImage.onload = () => {
        ctx.drawImage(chart.backgroundImage, chartArea.left, chartArea.top, chartArea.width, chartArea.height);
        chart.update(); // Trigger one update to render the background once
      };
    } else {
      ctx.drawImage(chart.backgroundImage, chartArea.left, chartArea.top, chartArea.width, chartArea.height);
    }
  },
};

function ActiveRadar({ players }) {
  const [playerImages, setPlayerImages] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (players && players.length > 0) {
      loadPlayerImages(players).then((images) => {
        setPlayerImages(images);
        setLoading(false);
      });
    }
  }, [players]);

  if (loading || !players || players.length === 0) {
    return <h1>Loading...</h1>;
  }

  // Map players' positions to bubble chart data points
  const playerData = players.map((player) => ({
    x: player.xPos,
    y: player.zPos,
    r: 5, // Set reference size for image
    label: player.username,
  }));

  const data = {
    datasets: [
      {
        label: 'Online Players',
        data: playerData,
      },
    ],
  };

  // Plugin to draw custom images as points
  const customPointStylePlugin = {
    id: 'customPointStyle',
    afterDraw(chart) {
      const { ctx } = chart;
      chart.data.datasets.forEach((dataset) => {
        dataset.data.forEach((dataPoint, index) => {
          const meta = chart.getDatasetMeta(0).data[index];
          const image = playerImages[dataPoint.label];

          if (image) {
            // Draw the image at the data point location with smaller dimensions
            ctx.drawImage(
              image,
              meta.x - 5, // Center the image on the x-axis
              meta.y - 5, // Center the image on the y-axis
              25,         // Fixed width
              25          // Fixed height
            );
          }
        });
      });
    },
  };

  return (
    <div className="Canvas">
      <Bubble options={options} data={data} plugins={[backgroundImagePlugin, customPointStylePlugin]} />
      <div style={{ marginTop: '20px' }}>
      </div>
    </div>
  );
}

export default ActiveRadar;
